import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';

const PromoModal: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(true);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <Modal
        fade
        backdrop
        isOpen={modalOpen}
        toggle={toggleModal}
        className="d-flex justify-content-center align-items-center flex-column h-100 promo-modal"
      >
        <ModalBody className="d-flex flex-column align-items-center">
          <strong>
            <h2>LIMITED EDITION VINYL</h2>
          </strong>
          <h5>ONLY AVAILABLE UNTIL DEC 22</h5>
          <img
            src={require('./assets/images/uche_promomodal.png')}
            alt="UCHE YARA PRE-ORDER"
            className="img-fluid w-100 mb-3 mt-2"
          />
          <p>
            This is your chance to order the super limited honey, vinyl. No reprints, no second chances. After December
            22, 2024, it will be gone forever.
          </p>
          <Link to="/product/78209">
            <button className="btn-lg btn btn-primary" onClick={toggleModal}>
              PRE-ORDER NOW
            </button>
          </Link>
        </ModalBody>
      </Modal>
      {children}
    </>
  );
};

export default PromoModal;
